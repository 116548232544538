import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M45.039 31.993h-12.191c-0.442 0-0.8 0.358-0.8 0.8v0.929c0 0.442 0.358 0.8 0.8 0.8h10.463v12.863c0.005 0.092 0.007 0.201 0.007 0.309 0 1.66-0.625 3.174-1.653 4.32l0.005-0.006c-1.072 1.085-2.561 1.757-4.206 1.757-0.138 0-0.274-0.005-0.41-0.014l0.018 0.001c-0.117 0.008-0.254 0.013-0.392 0.013-1.646 0-3.135-0.672-4.207-1.757l-0.001-0.001c-1.023-1.139-1.649-2.653-1.649-4.313 0-0.109 0.003-0.217 0.008-0.325l-0.001 0.015v-2.655c-0-0.442-0.358-0.8-0.8-0.8h-0.928c-0.442 0-0.8 0.358-0.8 0.8v2.687c-0.003 0.091-0.005 0.197-0.005 0.304 0 2.327 0.885 4.447 2.337 6.042l-0.007-0.007c1.528 1.567 3.659 2.538 6.018 2.538 0.15 0 0.299-0.004 0.446-0.012l-0.021 0.001c0.127 0.007 0.275 0.011 0.424 0.011 2.358 0 4.489-0.972 6.015-2.536l0.002-0.002c1.446-1.588 2.331-3.708 2.331-6.036 0-0.106-0.002-0.212-0.006-0.318l0 0.015v-14.623c-0.001-0.442-0.359-0.8-0.801-0.8v0z"          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
